import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import RefWindow from "../../components/RefWindow";
import { contractAbi, contractAddress } from "../../constants";
import UsersService from "../../services/users.service";
import "./ref-panel.scss";

const rankClass = {
  Unranked: "rnk0",
  Premier: "rnk1",
  Elite: "rnk2",
  Noble: "rnk3",
};

const levels = { r1: 0, r2: 0, r5: 0, r10: 0, r20: 0, r30: 0, r50: 0 };

function RefPanel(props) {
  const { moralisObj, setCurrentJob, onFlash } = props;
  const { isWeb3Enabled, isAuthenticated, user, enableWeb3, Moralis } =
    moralisObj;

  const [checking, setChecking] = useState(false);
  const [hasPackage, setHasPackage] = useState(false);

  const navigate = useNavigate();
  const [refs, setRefs] = useState({
    tempRefsCount: { ...levels },
    tempUniqueRefsCount: { ...levels },
    tempPackagesAmount: { ...levels },
    tempCmUniqueRefsCount: { ...levels },
    tempCmPackagesAmount: { ...levels },
  });

  const [rankTitle, setRankTitle] = useState("Member");
  const [paidRewards, setPaidRewards] = useState({
    l0LastMonth: 0,
    l0Total: 0,
    l1LastMonth: 0,
    l1Total: 0,
    l2LastMonth: 0,
    l2Total: 0,
    l3LastMonth: 0,
    l3Total: 0,
  });
  const [lvl6IsActive, setLvl6IsActive] = useState(false);
  const [lvl7IsActive, setLvl7IsActive] = useState(false);

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      setCurrentJob("Loading packages info...");
      if (!checking) checkInitialValues();
      setChecking(true);
    } else {
      enableWeb3();
    }
    if (!user) navigate(`/${window.location.search}`, { replace: true });
  }, [isWeb3Enabled, isAuthenticated, enableWeb3, navigate, user]);

  async function checkInitialValues() {
    try {
      const [userStats] = await Promise.all([
        UsersService.getReferrals(user.attributes.ethAddress),
        checkInvestments(),
      ]);

      setPaidRewards(userStats.data.paidRewards);
      setLvl6IsActive(userStats.data.paidRewards.l2Total > 0);
      setLvl7IsActive(userStats.data.paidRewards.l3Total > 0);

      let _rankTitle = "Member";
      if (userStats.data.paidRewards.l1Total > 0) {
        _rankTitle = "Premier";
        if (userStats.data.paidRewards.l2Total > 0) {
          _rankTitle = "Elite";
          if (userStats.data.paidRewards.l3Total > 0) {
            _rankTitle = "Noble";
          }
        }
      }
      setRankTitle(_rankTitle);

      const tempRefs = userStats.data.refs;
      const currentMonthRefs = userStats.data.currentMonthRefs;

      const uniqueRefs = tempRefs.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.from === value.from)
      );

      const uniqueCurrentMonthRefs = currentMonthRefs.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.from === value.from)
      );

      let tempRefsCount = { ...levels };
      let tempUniqueRefsCount = { ...levels };
      let tempPackagesAmount = { ...levels };

      let tempCmUniqueRefsCount = { ...levels };
      let tempCmPackagesAmount = { ...levels };

      for (let r of tempRefs) {
        const key = "r" + Math.floor(r.percentage * 10);
        tempRefsCount[key] = tempRefsCount[key] + 1;
        tempPackagesAmount[key] = tempPackagesAmount[key] + r.packagePrice;
      }
      for (let ur of uniqueRefs) {
        const key = "r" + Math.floor(ur.percentage * 10);
        tempUniqueRefsCount[key] = tempUniqueRefsCount[key] + 1;
      }

      for (let r of currentMonthRefs) {
        const key = "r" + Math.floor(r.percentage * 10);
        tempCmPackagesAmount[key] = tempCmPackagesAmount[key] + r.packagePrice;
      }
      for (let ur of uniqueCurrentMonthRefs) {
        const key = "r" + Math.floor(ur.percentage * 10);
        tempCmUniqueRefsCount[key] = tempCmUniqueRefsCount[key] + 1;
      }

      setRefs({
        tempRefsCount,
        tempUniqueRefsCount,
        tempPackagesAmount,
        tempCmPackagesAmount,
        tempCmUniqueRefsCount,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setCurrentJob("");
      setChecking(false);
    }
  }

  async function checkInvestments() {
    const fn = {
      contractAddress,
      functionName: "getUserActivePackages",
      abi: contractAbi,
      params: {
        user: user.attributes.ethAddress,
      },
    };
    const _userInfo = await Moralis.executeFunction(fn);
    let _hasPackage = false;
    _userInfo.map((pkg) => {
      if (pkg.isActive) {
        _hasPackage = true;
      }
      return pkg;
    });
    setHasPackage(_hasPackage);
  }

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand("copy");
      if (successful) {
        onFlash({ message: "Link copied to clipboard", type: "success" });
      } else {
        onFlash({ message: "Could not copy link", type: "danger" });
      }
    } catch (err) {
      onFlash({ message: "Could not copy link", type: "danger" });
    }
    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      function () {
        onFlash({ message: "Link copied to clipboard", type: "success" });
      },
      function () {
        fallbackCopyTextToClipboard(text);
      }
    );
  }

  return (
    <div className="container">
      <h3 className="st-header mb-2 mt-0">Referrals Report</h3>
      <div className="white-box mb-2">
        <b>
          Your rank is: <span style={{ color: "#ffe800" }}>{rankTitle}</span>
        </b>
        <div className="reflink-wrapper">
          <p>Invite your friends using the following link and earn rewards:</p>
          {hasPackage ? (
            <div
              className="reflink copyable"
              onClick={() =>
                copyTextToClipboard(
                  `https://dexfee.com/?ref=${user.attributes.ethAddress}`
                )
              }
            >
              <div>
                https://dexfee.com/?ref={user.attributes.ethAddress}
                <div className="copy-reflink">
                  <i className="fa-light fa-copy"></i>
                </div>
              </div>
            </div>
          ) : (
            <div className="reflink">
              <div style={{ textAlign: "center", whiteSpace: "normal" }}>
                Once your first package is activated, a referral link will be
                available.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="white-box mb-2" id="tour_referrals">
        <RefWindow
          refs={refs}
          lvl6IsActive={lvl6IsActive}
          lvl7IsActive={lvl7IsActive}
        />
      </div>
      <div className="row tight">
        <div className="col-12 col-md-6 col-xl-3 mb-2">
          <div className="white-box">
            <div className="rankbox-header tinted">
              <b>Earn from plan 1</b>
              <div className="yellow-text">For All Members</div>
            </div>
            <div className="rankbox-body">
              <div className="rb-col">
                <div>Last Month</div>
                <div>{paidRewards.l0LastMonth.toLocaleString()} Dexo</div>
              </div>
              <div className="rb-col">
                <div>Total</div>
                <div>{paidRewards.l0Total.toLocaleString()} Dexo</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-3 mb-2">
          <div className="white-box">
            <div className="rankbox-header tinted">
              <b>Earn from plan 2</b>
              <div className="yellow-text">
                Only For Premier Members & Higher
              </div>
            </div>
            <div className="rankbox-body">
              <div className="rb-col">
                <div>Last Month</div>
                <div>{paidRewards.l1LastMonth} 1SD</div>
              </div>
              <div className="rb-col">
                <div>Total</div>
                <div>{paidRewards.l1Total} 1SD</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-3 mb-2">
          <div className="white-box">
            <div className="rankbox-header tinted">
              <b>Earn from plan 3</b>
              <div className="yellow-text">Only For Elite Members & Higher</div>
            </div>
            <div className="rankbox-body">
              <div className="rb-col">
                <div>Last Month</div>
                <div>{paidRewards.l2LastMonth} 1SD</div>
              </div>
              <div className="rb-col">
                <div>Total</div>
                <div>{paidRewards.l2Total} 1SD</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-3 mb-2">
          <div className="white-box">
            <div className="rankbox-header tinted">
              <b>Earn from plan 4</b>
              <div className="yellow-text">Only For Noble Members</div>
            </div>
            <div className="rankbox-body">
              <div className="rb-col">
                <div>Last Month</div>
                <div>{paidRewards.l3LastMonth} 1SD</div>
              </div>
              <div className="rb-col">
                <div>Total</div>
                <div>{paidRewards.l3Total} 1SD</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="fiveplans">
        <div className="white-box">
          <div className="rankbox-header tinted">
            <b>Master Plan</b>
          </div>
          <p>For each new referred user, you will earn commissions in Dexo tokens based on their account registration level as follows:</p>
          <ul>
            <li>Level 1: 5%</li>
            <li>Level 2: 3%</li>
            <li>Level 3: 2%</li>
            <li>Level 4: 1%</li>
            <li>Level 5: 0.5%</li>
          </ul>
        </div>
        <div className="white-box">
          <div className="rankbox-header tinted">
            <b>Plan 1</b>
          </div>
          <p>If at least 20 new accounts are registered by your Level 1 referrals within a month, you will receive an additional 10% of the total registered amount in 1SD tokens and advance to the rank of Premier.</p>
        </div>
        <div className="white-box">
          <div className="rankbox-header tinted">
            <b>Plan 2</b>
          </div>
          <p>If your Level 2 referrals register at least 100 new accounts within a month, you will receive $5,000 in 1SD tokens, and Level 6 will be activated in the general plan with a 0.25% commission. This advancement will also elevate your rank to Elite. (Only available for Premier-ranked members.)</p>
        </div>
        <div className="white-box">
          <div className="rankbox-header tinted">
            <b>Plan 3</b>
          </div>
          <p>If your Level 3 referrals reach 500 new registrations in a month, you will receive $20,000 in 1SD tokens, and Level 7 will be activated in the general plan with a 0.125% commission, advancing your rank to Noble. (Only available for Elite-ranked members.)</p>
        </div>
        <div className="white-box">
          <div className="rankbox-header tinted">
            <b>Plan 4</b>
          </div>
          <p>If you achieve a total of 100,000 new registrations or a sales value of $50 million across 7 levels within 25 months, you will earn $1,000,000 in 1SD tokens and advance to the rank of Superior. (Available only to Noble-ranked members.)</p>
        </div>
      </div>
      <div className="plan-notes">
        <b>Additional Rank Advancements:</b>
        <p>If your total commission earnings reach $100,000, your rank will advance to Master.</p>
        <p>If your total commission earnings reach $1,000,000, your rank will also advance to Superior.</p>
      </div>
    </div>
  );
}

export default RefPanel;
