import axios from "axios"
import { BASE_URL } from "../constants";
import authHeader from "./authHeader"

class StakingService {
  stake(hash, wallet, preId) {
    return axios.post(BASE_URL + '/staking/stake', {hash, wallet, preId}, { headers: authHeader() });
  }
  unstake(id) {
    return axios.post(BASE_URL + '/staking/unstake', {id}, { headers: authHeader() });
  }
  prestake(session, amount) {
    return axios.post(BASE_URL + '/staking/prestake', {session, amount}, { headers: authHeader() });
  }
  removePrestake(session, preId) {
    return axios.post(BASE_URL + '/staking/remove-prestake', {session, preId}, { headers: authHeader() });
  }
  userStakes(wallet) {
    return axios.get(BASE_URL + '/staking/user-stakes?wallet=' + wallet, { headers: authHeader() });
  }
  activatePackage(hash, session, referral, user, activeCurrency, amount, preId) {
    return axios.post(BASE_URL + '/staking/activate-package', {hash, session, referral, user, activeCurrency, amount, preId}, { headers: authHeader() });
  }
  preActivatePackage(session, referral, activeCurrency, amount) {
    return axios.post(BASE_URL + '/staking/preactivate-package', {session, referral, activeCurrency, amount}, { headers: authHeader() });
  }
  removePreActivation(session, preId) {
    return axios.post(BASE_URL + '/staking/remove-preactivation', {session, preId}, { headers: authHeader() });
  }
}

export default new StakingService();