import React, {useState, useEffect, useCallback} from 'react'
import moment from 'moment'
import AdminService from '../../services/admin.service';
import DownloadCSV from '../DownloadCSV';

function SwapRequests(props) {
  const { setCurrentJob} = props
  const [submissions, setSubmissions] = useState([]);

  const loadData = useCallback(async () => {
    setCurrentJob('Loading data...')
    const loadedRequests = await AdminService.getSwapRequests()
    setSubmissions(loadedRequests.data)
    setCurrentJob('')
  },[])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <div className="admin-settings">
      <div className="white-box mb-2">
        <h4 style={{textAlign: 'center'}}>Swap Requests</h4>
        <hr />
        <DownloadCSV data={submissions.map((sb)=>[sb.createdAt, sb.wallet, sb.quantity, sb.hash])} fileName={'swap-requests'} />
        <hr />
        <table className='contact-table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Wallet</th>
              <th>Quantity</th>
              <th>Hash</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((sb,indx)=><tr key={sb+indx}>
              <td>{moment(sb.createdAt).format("MMM Do - HH:mm")}</td>
              <td>{sb.wallet}</td>
              <td>{sb.quantity}</td>
              <td>{sb.hash}</td>
            </tr>)}
          </tbody>
        </table>
        
      </div>
    </div>
  );
}

export default SwapRequests;