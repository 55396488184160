import React, { useCallback, useState } from "react";
import Tree from "react-d3-tree";

const containerStyles = {
  width: "100%",
  height: "70vh",
  backgroundColor: "#fff",
  borderRadius: "4px"
};

const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 2 });
    }
  }, []);
  return [translate, containerRef];
};

const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
  <g>
    <circle cx="0" cy="10" r="10" stroke="#dfcb00" strokeWidth="1" onClick={toggleNode} x="-10" />
    <text fill="black" strokeWidth="1" x="20">
      {nodeDatum.name.substr(0,5) + "..." + nodeDatum.name.substr(-4)}
    </text>
  </g>
);

const saveTree = () => {
  const svg = document.querySelector('.rd3t-svg')
  const g = svg.querySelector('g')
  let svgAsXML = (new XMLSerializer).serializeToString(svg)
  svgAsXML = svgAsXML.replaceAll('<path', '<path stroke="black" fill="none"')
  const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`
  const link = document.createElement("a");
  document.body.appendChild(link); 
  link.setAttribute("href", svgData);
  link.setAttribute("download", "image.svg");
  link.click();
}

function UsersListTree({data}) {
  const [translate, containerRef] = useCenteredTree();
  return (
    <div className="users-tree">
      <button className="sbtn sbtn-info" onClick={()=>saveTree()}>Save as SVG</button>
      <div style={containerStyles} ref={containerRef}>
        <Tree data={data} translate={translate} renderCustomNodeElement={renderRectSvgNode} orientation="vertical" />
      </div>
    </div>
  );
}

export default UsersListTree;