import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./side_menu.scss";
import tourSteps from "../../tourSteps";
import { Steps } from 'intro.js-react';

const pathsWithTour = ["", "dashboard", "packages", "staking"]

function SideMenu({logout, isAuthenticated, elevated, ancCount}) {
  const loc = useLocation()
  const currentPath = loc.pathname
  const search = loc.search

  const [unreadCount, setUnreadCount] = useState(0)
  const [stepsEnabled, setStepsEnabled] = useState(false);

  useEffect(()=>{
    setUnreadCount(ancCount - Number(localStorage.getItem('anc_count') || 0))
  },[ancCount])

  useEffect(()=>{
    setTimeout(()=>{
      const currentPathRefined = currentPath.replace('/','')
      if (!localStorage.getItem('tour_' + currentPathRefined) && isAuthenticated && pathsWithTour.indexOf(currentPathRefined) > -1) setStepsEnabled(true)
    },3000)
  },[currentPath])

  const onExit = () => {
    setStepsEnabled(false)
    localStorage.setItem('tour_' + currentPath.replace('/',''), true)
  };

  return (
    <div className="side-menu">
      <Steps
        enabled={stepsEnabled}
        initialStep={0}
        steps={tourSteps[currentPath] || []}
        onExit={onExit}
      />
      <div className="sm-header">
        <img src="/logo.png" alt="logo" />
        <img src="/logo2.png" alt="logo" />
      </div>
      <Link
        className={`sm-button ${currentPath === "/" ? "active" : ""}`}
        to={{ pathname: "/", search }}
      >
        <i className="fa-light fa-home"></i>
        <span>Home</span>
      </Link>
      {isAuthenticated ? (
        <React.Fragment>
          <Link
            className={`sm-button ${
              currentPath === "/dashboard" ? "active" : ""
            }`}
            to={{ pathname: "/dashboard", search }}
            id="tour_dashboard"
          >
            <i className="fa-light fa-user"></i>
            <span>Dashboard</span>
          </Link>
          <Link
            className={`sm-button ${
              currentPath === "/packages" ? "active" : ""
            }`}
            to={{ pathname: "/packages", search }}
            id="tour_packages"
          >
            <i className="fa-light fa-box-open"></i>
            <span>Packages</span>
          </Link>
          <Link
            className={`sm-button ${
              currentPath === "/transactions" ? "active" : ""
            }`}
            to={{ pathname: "/transactions", search }}
          >
            <i className="fa-light fa-list"></i>
            <span>Transactions</span>
          </Link>
          <Link
            className={`sm-button ${
              currentPath === "/staking" ? "active" : ""
            }`}
            to={{ pathname: "/staking", search }}
            id="tour_staking"
          >
            <i className="fa-light fa-coins"></i>
            <span>Staking</span>
          </Link>
          <Link
            className={`sm-button ${currentPath === "/refs" ? "active" : ""}`}
            to={{ pathname: "/refs", search }}
          >
            <i className="fa-light fa-users"></i>
            <span>Rewards & Plans</span>
          </Link>
          <Link
            className={`sm-button ${
              currentPath === "/announcements" ? "active" : ""
            }`}
            to={{ pathname: "/announcements", search }}
          >
            <i className="fa-light fa-bullhorn"></i>
            <span>Announcements</span>
            {unreadCount > 0 ? (
              <div className="sm-badge">{unreadCount}</div>
            ) : null}
          </Link>
          <Link
            className={`sm-button ${currentPath === "/swap" ? "active" : ""}`}
            to={{ pathname: "/swap", search }}
          >
            <i className="fa-light fa-repeat"></i>
            <span>Swap</span>
          </Link>
          <Link
            className={`sm-button ${currentPath === "/help" ? "active" : ""}`}
            to={{ pathname: "/help", search }}
          >
            <i className="fa-light fa-life-ring"></i>
            <span>Help</span>
          </Link>
        </React.Fragment>
      ) : null}
      {/* <Link className={`sm-button ${currentPath === '/plans' ? 'active' : ''}`} to={{ pathname: '/plans', search}} >
        <i className="fa-light fa-square"></i>
        <span>Plans</span>
      </Link> */}
      {elevated ? (
        <Link
          className={`sm-button ${
            currentPath.indexOf("admin") > -1 ? "active" : ""
          }`}
          to={{ pathname: "/admin/stats", search }}
        >
          <i className="fa-light fa-cogs"></i>
          <span>Administration</span>
        </Link>
      ) : null}
      <Link
        className={`sm-button ${currentPath === "/contact-us" ? "active" : ""}`}
        to={{ pathname: "/contact-us", search }}
      >
        <i className="fa-light fa-envelope"></i>
        <span>Contact Us</span>
      </Link>
      {isAuthenticated ? (
        <div className={`sm-button`} onClick={logout}>
          <i className="fa-light fa-sign-out"></i>
          <span>Logout</span>
        </div>
      ) : null}
    </div>
  );
}

export default SideMenu;