import React from "react";

function RefWindow(props) {
  const {lvl6IsActive, lvl7IsActive, refs} = props
  const {tempRefsCount, tempUniqueRefsCount, tempPackagesAmount, tempCmUniqueRefsCount, tempCmPackagesAmount} = refs
  return (
    <div className="ref-info">
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th rowSpan={2}>Reward</th>
              <th colSpan={3} className="text-center">All Time</th>
              <th colSpan={2} className="text-center tinted">Current Month</th>
            </tr>
            <tr>
              <th>Packages</th>
              <th>Users</th>
              <th>Packages Amount</th>
              <th className="tinted">Counting Users</th>
              <th className="tinted">Counting Packages</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Level 1 (5%)</th>
              <td>{tempRefsCount.r50}</td>
              <td>{tempUniqueRefsCount.r50}</td>
              <td>${tempPackagesAmount.r50.toLocaleString()}</td>
              <td className="tinted"><span className="yellow-text">{tempCmUniqueRefsCount.r50}</span>/20</td>
              <td className="tinted">${tempCmPackagesAmount.r50.toLocaleString()}</td>
            </tr>
            <tr>
              <th>Level 2 (3%)</th>
              <td>{tempRefsCount.r30}</td>
              <td>{tempUniqueRefsCount.r30}</td>
              <td>${tempPackagesAmount.r30.toLocaleString()}</td>
              <td className="tinted"><span className="yellow-text">{tempCmUniqueRefsCount.r30}</span>/100</td>
              <td className="tinted">${tempCmPackagesAmount.r30.toLocaleString()}</td>
            </tr>
            <tr>
              <th>Level 3 (2%)</th>
              <td>{tempRefsCount.r20}</td>
              <td>{tempUniqueRefsCount.r20}</td>
              <td>${tempPackagesAmount.r20.toLocaleString()}</td>
              <td className="tinted"><span className="yellow-text">{tempCmUniqueRefsCount.r20}</span>/500</td>
              <td className="tinted">${tempCmPackagesAmount.r20.toLocaleString()}</td>
            </tr>
            <tr>
              <th>Level 4 (1%)</th>
              <td>{tempRefsCount.r10}</td>
              <td>{tempUniqueRefsCount.r10}</td>
              <td>${tempPackagesAmount.r10.toLocaleString()}</td>
              <td className="tinted">{tempCmUniqueRefsCount.r10}</td>
              <td className="tinted">${tempCmPackagesAmount.r10.toLocaleString()}</td>
            </tr>
            <tr>
              <th>Level 5 (0.5%)</th>
              <td>{tempRefsCount.r5}</td>
              <td>{tempUniqueRefsCount.r5}</td>
              <td>${tempPackagesAmount.r5.toLocaleString()}</td>
              <td className="tinted">{tempCmUniqueRefsCount.r5}</td>
              <td className="tinted">${tempCmPackagesAmount.r5.toLocaleString()}</td>
            </tr>
            <tr className={lvl6IsActive ? '' : 'grayed'}>
              <th>Level 6 (0.25%)</th>
              <td>{tempRefsCount.r2}</td>
              <td>{tempUniqueRefsCount.r2}</td>
              <td>${tempPackagesAmount.r2.toLocaleString()}</td>
              <td className="tinted">{tempCmUniqueRefsCount.r2}</td>
              <td className="tinted">${tempCmPackagesAmount.r2.toLocaleString()}</td>
            </tr>
            <tr className={lvl7IsActive ? '' : 'grayed'}>
              <th>Level 7 (0.125%)</th>
              <td>{tempRefsCount.r1}</td>
              <td>{tempUniqueRefsCount.r1}</td>
              <td>${tempPackagesAmount.r1.toLocaleString()}</td>
              <td className="tinted">{tempCmUniqueRefsCount.r1}</td>
              <td className="tinted">${tempCmPackagesAmount.r1.toLocaleString()}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total Sales</th>
              <td className="yellow-text">{tempRefsCount.r50 + tempRefsCount.r30 + tempRefsCount.r20 + tempRefsCount.r10 + tempRefsCount.r5 + tempRefsCount.r2 + tempRefsCount.r1}</td>
              <td className="yellow-text">{tempUniqueRefsCount.r50 + tempUniqueRefsCount.r30 + tempUniqueRefsCount.r20 + tempUniqueRefsCount.r10 + tempUniqueRefsCount.r5 + tempUniqueRefsCount.r2 + tempUniqueRefsCount.r1}</td>
              <td className="yellow-text">${(tempPackagesAmount.r50 + tempPackagesAmount.r30 + tempPackagesAmount.r20 + tempPackagesAmount.r10 + tempPackagesAmount.r5 + tempPackagesAmount.r2 + tempPackagesAmount.r1).toLocaleString()}</td>
              <td className="yellow-text tinted">{tempCmUniqueRefsCount.r50 + tempCmUniqueRefsCount.r30 + tempCmUniqueRefsCount.r20 + tempCmUniqueRefsCount.r10 + tempCmUniqueRefsCount.r5 + tempCmUniqueRefsCount.r2 + tempCmUniqueRefsCount.r1}</td>
              <td className="yellow-text tinted">${(tempCmPackagesAmount.r50 + tempCmPackagesAmount.r30 + tempCmPackagesAmount.r20 + tempCmPackagesAmount.r10 + tempCmPackagesAmount.r5 + tempCmPackagesAmount.r2 + tempCmPackagesAmount.r1).toLocaleString()}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default RefWindow;