import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "./admin.scss";


function Admin() {
return <div className="container admin">
    <div className="admin-tabs">
      <NavLink className={`admin-tab`} to='stats'      >Statistics</NavLink>
      <NavLink className={`admin-tab`} to='users'      >Users</NavLink>
      <NavLink className={`admin-tab`} to='editor'     >Editor</NavLink>
      {/* <NavLink className={`admin-tab`} to='contract'   >Contract</NavLink> */}
      <NavLink className={`admin-tab`} to='packages'   >Packages</NavLink>
      <NavLink className={`admin-tab`} to='rewards'    >Rewards</NavLink>
      <NavLink className={`admin-tab`} to='submissions'>Submissions</NavLink>
      <NavLink className={`admin-tab`} to='swaps'      >Swap Requests</NavLink>
      <NavLink className={`admin-tab`} to='settings'   >Settings</NavLink>
    </div>
    <Outlet />
  </div>
}

export default Admin;