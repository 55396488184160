import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function StakingChart({chartData, chartData2, day, baseDexo}) {
  
  const [chartOptions1, setChartOptions1] = useState(null)

  async function setData() {
    const ccc = 9
    const chartOptions = {
      chart: {
        title: '',
        type: 'areaspline',
        backgroundColor: 'rgba(0,0,0,0)',
        height: '350px'
      },
      title:{
        text:''
      },
      yAxis: {
        min: Math.min(...chartData.values),
        max: Math.max(...chartData.values),
        visible: true,
        title: '',
        gridLineColor: 'rgba(255, 255, 255, 0.1)',
        labels:{
           formatter:function(){
               return (this.value > 1000 ? this.value/1000 + 'K' : this.value) + ' DEXO';
           },
           style: {
            color: '#fff'
          }
        }
      },
      tooltip: {
        formatter: function() {
          return 'ROI: <b>'+ Math.round((this.y/baseDexo-1)*1000)/10 +'%</b><br/>Return Amount: <b>'+ Highcharts.numberFormat(this.y, 0) +'</b><br/>in day: '+ this.x;
        }
      },
      xAxis:{
        title:{
          text: "Days",
          style: {
            color: '#fff'
          }
        },
        categories: chartData.categories,
        labels:{
          style: {
           color: '#fff'
         }
        },
        plotLines: [{
          color: '#ffe808',
          width: 2,
          value: day
        }]
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Value',
        data: chartData.values,
        lineColor: Highcharts.getOptions().colors[ccc],
        lineWidth: 1,
        marker: {
          enabled: false
        },
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, Highcharts.Color(Highcharts.getOptions().colors[ccc]).setOpacity(0.5).get('rgba')],
            [1, Highcharts.Color(Highcharts.getOptions().colors[ccc]).setOpacity(0).get('rgba')]
          ]
        }
      },{
        data: chartData2,
        lineColor: Highcharts.getOptions().colors[5],
        lineWidth: 1,
        marker: {
          enabled: false
        },
        fillColor: false
      }],
    }
    setChartOptions1(chartOptions)
  }
  useEffect(() => {
    setData()
  }, [chartData, day])

  return (
    <div className="charts-wrapper white-box">
      <h3>Return Amount Over Time</h3>
      {chartOptions1 ? <HighchartsReact highcharts={Highcharts} options={chartOptions1} /> : null}
    </div>
  );
}

export default StakingChart;