import React, {useState, useRef} from 'react'
import JoditEditor from "jodit-react";
import AdminService from '../../services/admin.service';

function EditorBox({setCurrentJob, onFlash, title, pos, content}) {
  const ref = useRef(null)
  const [config] = useState({
    colorPickerDefaultTab: 'text',
    sizeLG: 400,
    sizeMD: 400,
    sizeSM: 400,
    sizeXS: 400,
    buttons: [
      'source',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      'ul',
      'ol',
      'outdent', 'indent',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'align',
      'hr',
      'eraser',
      'fullsize',
      'image',
      'source'
    ],
    buttonsXS: [
      'bold',
      'fontsize',
      'paragraph',
      'fullsize'
    ]
  });

  const [editorContent, setEditorContent] = useState(content);
  const [collapsed, setCollapsed] = useState(true);

  async function saveChanges() {
    setCurrentJob('Saving data...')
    try {
      let res = await AdminService.saveContent(editorContent, pos)
      if (res.status === 201) {
        onFlash({message: 'Data has been saved', type:'success'})
      } else {
        onFlash({message: 'There was a problem while saving data.', type:'danger'})  
      }
    } catch (err) {
      onFlash({message: 'There was a problem while saving data. check the console.', type:'danger'})
      console.log(err)
    }finally {
      setCurrentJob('')
    }
  }
  return (
    <div className={`board-box ${collapsed ? 'bb-collapsed' : ''} mb-1`}>
      <h3>{title}</h3>
      <div className='collapse-buttotn' onClick={()=>setCollapsed(!collapsed)}>
        <i className="fa-light fa-chevron-down"></i>
        <i className="fa-light fa-chevron-up"></i>
      </div>
      <JoditEditor value={editorContent} onChange={(c)=>setEditorContent(c)} ref={ref} config={config}/>
      <div className='editor-footer'>
        <button className='sbtn sbtn-primary' onClick={()=>saveChanges()}>Save</button>
      </div>
    </div>
  );
}

export default EditorBox;