import React, { useEffect, useState } from 'react'
import AdminService from '../../services/admin.service'
import PackagesService from '../../services/packages.service'
import PackagesChart from './PackagesChart'
import moment from 'moment'

const initialStats = {
  pkg0: {
    title: 'Package 1',
    by1sd: 0,
    byDexo: 0,
    byUsd: 0,
    total: 0
  },
  pkg1: {
    title: 'Package 2',
    by1sd: 0,
    byDexo: 0,
    byUsd: 0,
    total: 0
  },
  pkg2: {
    title: 'Package 3',
    by1sd: 0,
    byDexo: 0,
    byUsd: 0,
    total: 0
  },
  pkg3: {
    title: 'Featured Package',
    by1sd: 0,
    byDexo: 0,
    byUsd: 0,
    total: 0
  }
}

function AdminStats() {
  
  const [activeStats, setActiveStats] = useState ('packages')
  const [totalValue, setTotalValue] = useState (0)
  const [totalCount, setTotalCount] = useState (0)
  const [usersCount, setUsersCount] = useState (0)
  const [chartData, setChartData] = useState ()
  const [packagesStats, setPackagesStats] = useState ({...initialStats})
  const [stakingsStats, setStakingsStats] = useState ({
    stakedDexo: 0,
    stakesCount: 0,
    unstakedDexo: 0,
    unstakesCount: 0,
    dexoInterests: 0
  })
  const [stakingChartData, setStakingChartData] = useState ()

  useEffect(()=>{
    loadStats()
  },[])

  const loadStats = async () => {
    const packages = await PackagesService.getAllPackages()
    let priceList = {}
    packages.data.map((pk,indx)=>priceList[pk.price] = 'pkg'+indx)
    const stats = await AdminService.getStats()
    let _packagesStats = JSON.parse((JSON.stringify(initialStats)))
    let _totalValue = 0
    let _totalCount = 0
    let _chartsData = {values: [], categories: []}
    let prevDate = ''
    stats.data.packageActivations.map(pkg=>{
      const pkgKey = priceList[pkg.packagePrice]
      if (pkg.currency === 'usdt') _packagesStats[pkgKey].byUsd += 1
      if (pkg.currency === 'dexo') _packagesStats[pkgKey].byDexo += 1
      if (pkg.currency === '1sd') _packagesStats[pkgKey].by1sd += 1
      _packagesStats[pkgKey].total += pkg.packagePrice
      _totalValue += pkg.packagePrice
      _totalCount++
      const pkgDate = moment(pkg.activationDate).format('MMM YY')
      if (prevDate === pkgDate) {
        _chartsData.values[_chartsData.values.length-1] += pkg.packagePrice
      } else {
        _chartsData.values.push(pkg.packagePrice)
        _chartsData.categories.push(pkgDate)
      }
      prevDate = pkgDate
    })
    setChartData(_chartsData)
    setTotalValue(_totalValue)
    setTotalCount(_totalCount)
    setUsersCount(stats.data.usersCount)
    setPackagesStats(_packagesStats)
    
    let _stakedDexo = 0
    let _stakesCount = 0
    let _unstakedDexo = 0
    let _unstakesCount = 0
    let _dexoInterests = 0
    let _stakingChartData = {values: [], categories: []}
    prevDate = ''
    stats.data.stakings.map(stk=>{
      const amount = Number(stk.amount)/1000000000000000000
      if (stk.status === 'staked') {
        _stakedDexo += amount
        _stakesCount++
      }
      if (stk.status === 'unstaked') {
        const return_amount = Number(stk.return_amount)/1000000000000000000
        _unstakedDexo += amount
        _unstakesCount++
        _dexoInterests += return_amount - amount
      }
      const stkDate = moment(stk.createdAt).format('MMM YY')
      if (prevDate === stkDate) {
        _stakingChartData.values[_stakingChartData.values.length-1] += amount
      } else {
        _stakingChartData.values.push(amount)
        _stakingChartData.categories.push(stkDate)
      }
      prevDate = stkDate
    })
    setStakingsStats({
      stakedDexo: _stakedDexo,
      stakesCount: _stakesCount,
      unstakedDexo: _unstakedDexo,
      unstakesCount: _unstakesCount,
      dexoInterests: _dexoInterests
    })
    setStakingChartData(_stakingChartData)
  }

  function packageDetails(pkgStats) {
    let result = []
    for (const key in pkgStats) {
      const pkg = pkgStats[key]
      result.push(
        <div className='col-md-6 col-lg-3' key={key}>
          <div className='white-box pkg-stat'>
            <h3>{pkg.title}</h3> 
            <div className='pkg-stats-col'>
              <div>
                <span>By USDT</span>
                <span>{pkg.byUsd}</span>
              </div>
              <div>
                <span>By 1SD</span>
                <span>{pkg.by1sd}</span>
              </div>
              <div>
                <span>By DEXO</span>
                <span>{pkg.byDexo}</span>
              </div>
            </div>
            <div className='pkg-stats-row'>
              <span>Total Count:</span>
              <span>{pkg.byUsd + pkg.by1sd + pkg.byDexo}</span>
            </div>
            <div className='pkg-stats-row'>
              <span>Total Value:</span>
              <span>${pkg.total}</span>
            </div>
          </div>
        </div>)
    }
    return result
  }
  return <div>
    <div className='stats-tabs'>
      <div className={activeStats === 'packages' ? 'active' : ''} onClick={()=>setActiveStats('packages')}>Packages</div>
      <div className={activeStats === 'stakings' ? 'active' : ''} onClick={()=>setActiveStats('stakings')}>Stakings</div>
    </div>
    {activeStats === 'packages' ? <div>
      <div className='row tight'>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-users'></i>
            <h3>Total Users</h3>
            <div>{usersCount}</div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-boxes'></i>
            <h3>Packages Activated</h3>
            <div>{totalCount}</div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-money-bill-wave'></i>
            <h3>Packages Value</h3>
            <div>${totalValue}</div>
          </div>
        </div>
      </div>
      <div className='row tight'>
        {packageDetails(packagesStats)}
      </div>
      <div className='pkg-chart'>
        {chartData ? <PackagesChart title={'Monthly Package Activation'} chartData={chartData} /> : null }
      </div>
    </div> : <div>
      <div className='row tight'>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className="fa-light fa-inbox-in"></i>
            <h3>Active Stakings</h3>
            <div>{stakingsStats.stakedDexo} DEXO ({stakingsStats.stakesCount})</div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className="fa-light fa-inbox-out"></i>
            <h3>Previously Staked</h3>
            <div>{stakingsStats.unstakedDexo} DEXO ({stakingsStats.unstakesCount})</div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-hand-holding-dollar'></i>
            <h3>Paid Interest</h3>
            <div>{stakingsStats.dexoInterests} DEXO</div>
          </div>
        </div>
      </div>
      <div className='pkg-chart'>
        {stakingChartData ? <PackagesChart title={'Monthly Staking'} chartData={stakingChartData} /> : null }
      </div>
    </div> }
  </div>
}

export default AdminStats;