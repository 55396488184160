import React, {useState, useEffect} from 'react'
import usersService from '../../services/users.service'
import './swap.scss'
import { isAddress } from 'ethers/lib/utils'

function Swap(props) {
  const {moralisObj, setCurrentJob, onFlash} = props
  const {user} = moralisObj

  const [wallet, setWallet] = useState('')
  const [dexoQuantity, setDexoQuantity] = useState('')
  const [transactionHash, setTransactionHash] = useState('')

  const [invalidAddress, setInvalidAddress] = useState(false)
  const [invalidHash, setInvalidHash] = useState(false)
  const [invalidQuantity, setInvalidQuantity] = useState(false)

  async function sendSwapRequest() {
    setInvalidAddress(!isAddress(wallet));
    setInvalidQuantity(!(Number(dexoQuantity) > 0));
    setInvalidHash(transactionHash.trim() < 66);
    if (!isAddress(wallet) || !(Number(dexoQuantity) > 0) || transactionHash.length < 66) return;
    setCurrentJob("Loading data...");
    try {
      console.log("sending swap request");
      const res = await usersService.swap(
        user?.attributes?.sessionToken || "",
        wallet,
        dexoQuantity,
        transactionHash
      );
      console.log(res);
      if (res.data === "done") {
        onFlash({
          message: "Your request has been submitted.",
          type: "success",
        });
        setWallet("");
        setDexoQuantity("");
        setTransactionHash("");
      } else if (res.data === "alreadySent") {
        onFlash({ message: "A swap request for this hash already exists.", type: "warning" });
      } else if (res.data === "tooMany") {
        onFlash({ message: "Too many requests! try again later.", type: "danger" });
      } else {
        onFlash({ message: "There was an error!", type: "danger" });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrentJob("");
    }
  }

  return (
    <div className="container swap-form">
      <div className="admin-fn-box white-box">
        <h4>Swap your DEXO for 1SD</h4>
        <p>
          Please send your DEXO to the address below, then submit your
          transaction details using the form provided.
        </p>
        <div className="swap-qr">
          <img src="/qr_dead.png" alt="dead address qr code" width={200} />
        </div>
        <code>0x000000000000000000000000000000000000dEaD</code>
        <p>
          You will receive 1 SD at a rate of 0.0000025, credited to your wallet.
        </p>
        <div className="afb-body">
          <div className={`afb-input ${invalidAddress ? "invalid" : ""}`}>
            <label>Your Wallet Address</label>
            <input
              value={wallet}
              className="cs-textinput"
              placeholder="0x"
              onChange={(e) => setWallet(e.target.value)}
            />
            <span className="validation-text">
              Please enter a valid address
            </span>
          </div>
          <div className={`afb-input ${invalidQuantity ? "invalid" : ""}`}>
            <label>DEXO Quantity</label>
            <input
              value={dexoQuantity}
              className="cs-textinput"
              onChange={(e) =>
                setDexoQuantity(
                  Number(e.target.value) > 0
                    ? Number(e.target.value).toString()
                    : "0"
                )
              }
            />
            <span className="validation-text">Please enter a value</span>
          </div>
          <div className={`afb-input ${invalidHash ? "invalid" : ""}`}>
            <label>Transaction Hash</label>
            <textarea
              className="cs-textinput"
              onChange={(e) => setTransactionHash(e.target.value)}
              value={transactionHash}
              rows={2}
              maxLength={120}
            ></textarea>
            <span className="validation-text">Transaction hash is required</span>
          </div>
          <button
            className="sbtn sbtn-primary"
            onClick={() => sendSwapRequest()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Swap;