import axios from "axios"
import { BASE_URL } from "../constants";
import authHeader from "./authHeader"

class UsersService {
  getReferrals(wallet) {
    return axios.get(BASE_URL + '/user/referrals?wallet=' + wallet);
  }
  referralExists(wallet) {
    return axios.get(BASE_URL + '/user/referral-exists?wallet=' + wallet);
  }
  validReferral(user, referral) {
    return axios.get(BASE_URL + '/user/valid-referral?user=' + user + '&ref=' + referral);
  }
  contact(session, email, subject, message) {
    return axios.post(BASE_URL + '/user/contact', {session, email, subject, message}, { headers: authHeader() });
  }
  swap(session, wallet, quantity, hash) {
    return axios.post(BASE_URL + '/user/swap', {session, wallet, quantity, hash}, { headers: authHeader() });
  }
  getContent() {
    return axios.get(BASE_URL + '/get-content');
  }
  getAnnouncements() {
    return axios.get(BASE_URL + '/get-announcements');
  }
  getDashboardData(session) {
    return axios.post(BASE_URL + '/user/get-dashboard-data', { session });
  }
}

export default new UsersService();