import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function PackagesChart({chartData, title}) {
  
  const [chartOptions1, setChartOptions1] = useState(null)

  async function getChartData() {
    const ccc = 9
    //const chartData = {
    //  values: [1000, 8000, 3000, 22000, 17000, 25000],
    //  categories: ['Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22']
    //}
    return {
      chart: {
        title: '',
        type: 'areaspline',
        backgroundColor: 'rgba(0,0,0,0)',
        height: '170px'
      },
      title:{
        text:''
      },
      yAxis: {
        min: Math.min(...chartData.values),
        max: Math.max(...chartData.values),
        visible: true,
        title: '',
        gridLineColor: 'rgba(255, 255, 255, 0.1)',
        labels:{
           formatter:function(){
               return '$' + (this.value > 1000 ? this.value/1000 + 'K' : this.value);
           },
           style: {
            color: '#fff'
          }
        }
      },
      xAxis:{
        categories: chartData.categories,
        labels:{
          style: {
           color: '#fff'
         }
       }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Value',
        data: chartData.values,
        lineColor: Highcharts.getOptions().colors[ccc],
        lineWidth: 1,
        marker: {
          enabled: false
        },
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, Highcharts.Color(Highcharts.getOptions().colors[ccc]).setOpacity(0.5).get('rgba')],
            [1, Highcharts.Color(Highcharts.getOptions().colors[ccc]).setOpacity(0).get('rgba')]
          ]
        }
      }],
    }
  }
  
  async function setData() {
    const _ch1 = await getChartData()
    setChartOptions1(_ch1 || null)
  }
  useEffect(() => {
    setData()
  }, [])

  return (
    <div className="charts-wrapper white-box">
      <h3>{title}</h3>
      <div className="chart-wrapper">
        {chartOptions1 ? <HighchartsReact highcharts={Highcharts} options={chartOptions1} /> : null}
      </div>
    </div>
  );
}

export default PackagesChart;