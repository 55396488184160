import React from "react";

function GuestPage(props) {
  const {refStatus} = props
  return (
    <React.Fragment>
      <div className="guest-header">
        <img src="/logo-text.png" alt="logo"/>
      </div>
      <div className={refStatus === 0 ? 'busy-overlay active' : 'busy-overlay'}>
        <div className="spinner"></div>
        <div className="spinner-text">Validating Referral link...</div>
      </div>
    </React.Fragment>
  );
}

export default GuestPage;