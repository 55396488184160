import React, {useState, useEffect, useCallback, useMemo} from 'react'
import AdminService from '../../services/admin.service';
import UsersListTable from './UsersList_table';
import UsersListTree from './UsersList_tree';

function UsersList(props) {
  const { setCurrentJob} = props
  const [users, setUsers] = useState();
  const [usersTree, setUsersTree] = useState();
  const [viewMode, setViewMode] = useState('list');
  
  const loadData = useCallback(() => {
    (async () => {
      setCurrentJob('Loading data...')
      const loadedUsers = await AdminService.getUsers()
      setUsers(loadedUsers.data.filter(lu=>lu.rank))
      const usersTree = await AdminService.getUsersTree()
      setUsersTree(usersTree.data)
      setCurrentJob('')
    })();
  },[])
  
  const columns = useMemo(
    () => [
      {
        Header: "Wallet",
        accessor: "ethAddress",
        className: 'mono',
        Cell: ({ cell: { value } }) => {
          return (
            <span className='mono'>
              {value.substr(0,5) + "..." + value.substr(-4)}
            </span>
          )
        }
      }, {
        Header: "Rank",
        accessor: "rank",
        sortType: ((rowA, rowB, id) => {
          let rankA = 0
          if (rowA.values[id].level1 >= 20) {
            rankA = 1
            if (rowA.values[id].level2 >= 100) {
              rankA = 2
              if (rowA.values[id].level3 >= 500) {
                rankA = 3
              }
            }
          }

          let rankB = 0
          if (rowB.values[id].level1 >= 20) {
            rankB = 1
            if (rowB.values[id].level2 >= 100) {
              rankB = 2
              if (rowB.values[id].level3 >= 500) {
                rankB = 3
              }
            }
          }

          if(rankA > rankB) return 1
          if(rankA < rankB) return -1
          return 0;
        }),
        Cell: ({ cell: { value } }) => {
          let rankTitle = "-"
          if (value.level1 >= 20) {
            rankTitle = "Premier"
            if (value.level2 >= 100) {
              rankTitle = "Elite"
              if (value.level3 >= 500) {
                rankTitle = "Noble"
              }
            }
          }
          return (
            <>
              {rankTitle}
            </>
          );
        }
      }, {
        Header: "L1 Progress (20)",
        accessor: "rank.level1",
        Cell: ({ cell: { value } }) => <>
          <div className='rank-progress rp1'>
            <span>{value}</span>
            <div style={{width: value*5+'%'}}></div>
            <i></i>
          </div>
        </>
      }, {
        Header: "L2 Progress (100)",
        accessor: "rank.level2",
        Cell: ({ cell: { value } }) => <>
          <div className='rank-progress rp2'>
            <span>{value}</span>
            <div style={{width: value+'%'}}></div>
            <i></i>
          </div>
        </>
      }, {
        Header: "L3 Progress (500)",
        accessor: "rank.level3",
        Cell: ({ cell: { value } }) => <>
          <div className='rank-progress rp3'>
            <span>{value}</span>
            <div style={{width: value/5+'%'}}></div>
            <i></i>
          </div>
        </>
      }
    ],
    []
  );

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <div className="admin-settings">
      <div className="white-box mb-2">
        <div className='admn-header'>
          <h4>All Users</h4>
          {viewMode === 'list' ? <button className="sbtn" onClick={()=>{setViewMode('tree')}}>Tree View</button> : null }
          {viewMode === 'tree' ? <button className="sbtn" onClick={()=>{setViewMode('list')}}>List View</button> : null }
        </div>
        {users && viewMode === 'list' ? <UsersListTable columns={columns} data={users} /> : null}
        {usersTree && viewMode === 'tree'? <UsersListTree data={usersTree} /> : null}
      </div>
    </div>
  );
}

export default UsersList;