import React, {useState, useEffect} from 'react'
import UsersService from '../services/users.service';

function Boards({setCurrentJob}) {
  const [board1, setBoard1] = useState('');
  const [board2, setBoard2] = useState('');
  const [board3, setBoard3] = useState('');

  useEffect(() => {
    loadData()
  }, [])

  async function loadData () {
    try {
      setCurrentJob('Loading data...')
      const contentsRes = await UsersService.getContent()
      for (let cn of contentsRes.data) {
        if(cn.position === 'hb1') setBoard1(cn.content)
        if(cn.position === 'hb2') setBoard2(cn.content)
        if(cn.position === 'hb3') setBoard3(cn.content)
      }
    } catch (err) {
    } finally {
      setCurrentJob('')
    }
  }

  return (
    <div className="boards">
      <div className="row tight">
        <div className="col-12 col-md-4">
          <div className="board-box">
            <div dangerouslySetInnerHTML={{ __html: board1 }}></div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="board-box">
            <div dangerouslySetInnerHTML={{ __html: board2 }}></div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="board-box">
            <div dangerouslySetInnerHTML={{ __html: board3 }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Boards;