import React, { useEffect, useState, useRef } from 'react'
import JoditEditor from "jodit-react";
import moment from 'moment'
import Announcement from '../components/Announcement'
import UsersService from '../services/users.service'
import AdminService from '../services/admin.service'

function Announcements({setCurrentJob, ancCount, elevated}) {
  const [anc, setAnc] = useState([])
  const [newAncTitle, setNewAncTitle] = useState('')
  const [newAncText, setNewAncText] = useState('')
  const [selectedColor, setSelectedColor] = useState('green')
  const ref = useRef(null)
  const [config] = useState({
    colorPickerDefaultTab: 'text',
    sizeLG: 400,
    sizeMD: 400,
    sizeSM: 400,
    sizeXS: 400,
    buttons: [
      'source',
      'bold',
      'strikethrough',
      'underline',
      'italic',
      'ul',
      'ol',
      'outdent', 'indent',
      'fontsize',
      'brush',
      'paragraph',
      'table',
      'link',
      'align',
      'hr',
      'eraser',
      'fullsize',
      'image',
      'source'
    ],
    buttonsXS: [
      'bold',
      'fontsize',
      'paragraph',
      'fullsize'
    ]
  });

  useEffect(()=>{
    loadAnnouncements()
    localStorage.setItem('anc_count', ancCount)
  },[])

  async function loadAnnouncements () {
    try {
      setCurrentJob('Loading...')
      const contentsRes = await UsersService.getAnnouncements()
      setAnc(contentsRes.data)
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
    }
  }

  async function deleteAnc(id) {
    try {
      setCurrentJob('Deleting...')
      const delAncRes = await AdminService.delAnnouncements(id)
      if (delAncRes.status === 201) {
        setAnc(anc.filter(annc=> annc._id !== id))
      } else {
        console.log(delAncRes)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
    }
  }

  async function addAnc() {
    try {
      setCurrentJob('Posting Announcement...')
      const postAncRes = await AdminService.postAnnouncements({title: newAncTitle, text: newAncText, type: selectedColor})
      if (postAncRes.status === 201) {
        setAnc([{_id: postAncRes.data.insertedId, title: newAncTitle, text: newAncText, type: selectedColor},...anc])
      } else {
        console.log(postAncRes)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
    }
  }

   async function saveChanges(target, content) {
    /* setCurrentJob('Saving data...')
    try {
      let res = await AdminService.saveContent(content, target)
      if (res.status === 201) {
        onFlash({message: 'Data has been saved', type:'success'})
      } else {
        onFlash({message: 'There was a problem while saving data.', type:'danger'})  
      }
    } catch (err) {
      onFlash({message: 'There was a problem while saving data. check the console.', type:'danger'})
      console.log(err)
    }finally {
      setCurrentJob('')
    } */
  }

  return <div className="container">
    {elevated ? <div className='white-box'>
      <div className="afb-body">
        <div className="afb-input">
          <label>Title</label>
          <input className="cs-textinput" value={newAncTitle} onChange={e=>setNewAncTitle(e.target.value)} ></input>
        </div>
        <div className="afb-input">
          <label>Text</label>
          <JoditEditor value={newAncText} onChange={(c)=>setNewAncText(c)} ref={ref} config={config}/>
          {/* <textarea rows="3" className="cs-textinput" value={newAncText} onChange={e=>setNewAncText(e.target.value)} ></textarea> */}
        </div>
        <div className="afb-input">
          <label>Color</label>
          <div className='colors-group'>
            <div onClick={()=>setSelectedColor('green')}  className={`color-button cb-green ${selectedColor === 'green' ? 'checked' : null}`}></div>
            <div onClick={()=>setSelectedColor('yellow')} className={`color-button cb-yellow ${selectedColor === 'yellow' ? 'checked' : null}`}></div>
            <div onClick={()=>setSelectedColor('red')}    className={`color-button cb-red ${selectedColor === 'red' ? 'checked' : null}`}></div>
            <button className='sbtn sbtn-primary' onClick={addAnc}>Post</button>
          </div>
        </div>
      </div>
    </div> : null }
    {anc.map(ancmnt=> <Announcement
      key={ancmnt._id}
      adminMode={elevated}
      ancId={ancmnt._id}
      title={ancmnt.title}
      state={ancmnt.type}
      date={moment(ancmnt._created_at).format('MMM D YYYY - HH:mm')}
      onDelete={()=>deleteAnc(ancmnt._id)}
    >
      {ancmnt.text}
    </Announcement>)}
  </div>
}

export default Announcements;