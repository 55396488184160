import React, {useEffect, useState} from "react";

function MarketTrend() {
  const [tokens, setTokens] = useState([])
  async function getTokensList() {
    try {
      const assets = await fetch(`https://www.binance.com/bapi/composite/v1/public/marketing/recommend/hotAsset/list?currency=USD&type=2`)
      const assetsJson = await assets.json()
      let initialList = []
      assetsJson.data.map(t=>initialList.push(t.symbol))
      const products = await fetch(`https://www.binance.com/bapi/asset/v2/public/asset-service/product/get-products?includeEtf=true`)
      const productsJson = await products.json()
      const filteredTokens = productsJson.data.filter(t2=> initialList.includes(t2.s)).map(ft=>{
        ft.changes = Math.round((((Number(ft.c) - Number(ft.o)) * 100)/Number(ft.o) + Number.EPSILON) * 100) / 100
        return ft
      })
      setTokens(filteredTokens)
    } catch (err) {
      console.log('Failed to fetch market trends!')
    }
  }
  useEffect(() => {
    getTokensList()
  }, [])

  return (
    <div className="tokens-info">
      <h3>Market Trend</h3>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Last Price</th>
            <th>24h Change</th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((tk,indx)=><tr key={`ti${indx}`}>
            <th>{tk.b} <span className="ti-small">{tk.an}</span></th>
            <td>${tk.c}</td>
            <td>
              {tk.changes < 0 ? 
              <i className="fa-light fa-chevron-down"></i> : tk.changes > 0 ?
              <i className="fa-light fa-chevron-up"></i> : null }
              {tk.changes}%
            </td>
          </tr>)}
        </tbody>
      </table>
    </div>
  );
}

export default MarketTrend;