import React from 'react'
import "./radio_button.scss"

function RadioButton(props) {
  const {children, checked, onRbClick} = props

  return (
    <div className={`radio-button ${checked ? 'checked' : ''}`} onClick={onRbClick}>
      <div><span></span></div>
      <label>{children}</label>
    </div>
  );
}

export default RadioButton;