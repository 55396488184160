import React from "react";
import "./announcement.css"
function Announcement(props) {
  const {title, children, date, state, onDelete, adminMode} = props
  return (
    <div className={`annce-body ab-${state}`}>
      {adminMode ? <div className="ab-remove" onClick={onDelete}>
        <i className="fa-light fa-remove"></i>
      </div> : null}
      <div className="ab-sign">
        <i className={`fa-light fa-exclamation-${state === 'green' ? 'square' : state === 'yellow' ? 'triangle' : 'circle'}`}></i>
      </div>
      <div className="ab-text">
        <h4>{title} <span className="anc-date">{date}</span></h4>
        <div dangerouslySetInnerHTML={{ __html: children }}></div>
      </div>
    </div>
  );
}

export default Announcement;