import React, { useState } from "react";
import { useTable, useFilters, useSortBy } from "react-table";

function UsersListTable({ columns, data }) {
  const [filterInput, setFilterInput] = useState("");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter
  } = useTable(
    {
      columns,
      data
    },
    useFilters,
    useSortBy
  );

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("ethAddress", value);
    setFilterInput(value);
  };

  const getRank = ranks => {
    let rankClass = "rnk0"
    if (ranks.level1 >= 20) {
      rankClass = "rnk1"
      if (ranks.level2 >= 100) {
        rankClass = "rnk2"
        if (ranks.level3 >= 500) {
          rankClass = "rnk3"
        }
      }
    }
    return rankClass
  };

  return (
    <>
      <input
        className="cs-textinput"
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"Search by wallet"}
      />
      <table {...getTableProps()}>
         <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={getRank(row.cells[1].value)}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default UsersListTable;