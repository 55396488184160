import React, { useState, useEffect } from "react";
import "./rss.css";

export default function RSS() {
  const [items, setItems] = useState([]);

  useEffect(()=>{
    getRss()
  },[])
  
  const getRss = async (e) => {
    try {
      const res = await fetch(
        `https://api.allorigins.win/get?url=https://cointelegraph.com/rss/tag/dex`
      );
      const { contents } = await res.json();
      const feed = new window.DOMParser().parseFromString(contents, "text/xml");
      const items = feed.querySelectorAll("item");
      const feedItems = [...items].map((el) => {
        return {
          link: el.querySelector("link").innerHTML,
          title: el
            .querySelector("title")
            .innerHTML.replace("<![CDATA[", "")
            .replace("]]>", ""),
          img: el.querySelector("enclosure").getAttribute("url"),
        };
      });
      setItems(feedItems.splice(0, 10));
    } catch (error) {}
  };

  return (
    <div className="news-feed">
      <div className="white-box">
        <h3>Crypto News</h3>
        <div className="news-scroller">
          <div className="row">
          {items.map((item,indx) => 
            <div className="col-12 col-sm-6 col-md-12" key={`news${indx}`}>
              <a href={item.link} className="news-card" rel="noreferrer" target='_blank' >
                <img src={item.img} />
                <b>{item.title }</b>
              </a>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}