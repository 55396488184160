import React, {useState, useEffect, useCallback} from 'react'
import AdminService from '../../services/admin.service';

function AdminSettings(props) {
  const {moralisObj, setCurrentJob, onFlash} = props
  const {user} = moralisObj
  const [dexoUsdRate, setDexoUsdRate] = useState(0);
  const [apy, setApy] = useState(0);
  const [lvl1, setLvl1] = useState(0);
  const [lvl2, setLvl2] = useState(0);
  const [lvl3, setLvl3] = useState(0);
  const [lvl4, setLvl4] = useState(0);
  const [lvl5, setLvl5] = useState(0);
  const [autoConfirm, setAutoConfirm] = useState(false);
  
  const loadData = useCallback(async () => {
    setCurrentJob('Loading data...')
    const settings = await AdminService.getSettings()
    settings.data.map(st=>{
      if (st.settingId === 'conversion_rate') setDexoUsdRate(st.settingValue)
      if (st.settingId === 'apy') setApy(st.settingValue)
      if (st.settingId === 'auto_confirm_claims') setAutoConfirm(st.settingValue)
      if (st.settingId === 'reward_percents') {
        const _prTemp = (st.settingValue || "0,0,0,0,0").split(',')
        setLvl1(_prTemp[0])
        setLvl2(_prTemp[1])
        setLvl3(_prTemp[2])
        setLvl4(_prTemp[3])
        setLvl5(_prTemp[4])
      }
    })
    setCurrentJob('')
  },[])

  useEffect(() => {
    loadData()
  }, [loadData])

  const isValidNumbers = (numbers) => {
    let isValid = true
    const validatedNumbers = numbers.map(num => {
      if (Number(num) < 0 || isNaN(num)) isValid = false
      return Number(num)
    })
    if (isValid) {
      return validatedNumbers
    } else {
      return false
    }
  }

  async function saveChanges() {
    setCurrentJob('Saving data...')
    try {
      const percentages = isValidNumbers([apy, lvl1, lvl2, lvl3, lvl4, lvl5])
      if (!percentages) throw ('Invalid Input')
      await AdminService.saveSettings({settings: {percentages: percentages.join(','), auto_confirm_claims: autoConfirm}, wallet: user.attributes.accounts[0]})
      onFlash({message: 'Data has been saved', type:'success'})
    } catch (err) {
      if (err === "Invalid Input") {
        onFlash({message: 'Please enter valid numbers', type:'danger'})  
      } else {
        onFlash({message: 'There was a problem while saving data. check the console.', type:'danger'})
      }
      console.log(err)
    } finally {
      setCurrentJob('')
    }
  }

  return (
    <div className="admin-settings">
      <div className="admin-fn-box white-box mb-2">
        <h4>Settings</h4>
        <div className='row'>
          <div className='col-xs-12 col-md-4'>
            <div className="afb-input">
              <label>Conversion Rate - DEXO/USD</label>
              <input className="cs-textinput" value={dexoUsdRate} disabled readOnly></input>
            </div>
          </div>
          <div className='col-xs-12 col-md-4'>
            <div className="afb-input">
              <label>APY (%)</label>
              <input className="cs-textinput" value={apy} onChange={(e)=>setApy(e.target.value)}></input>
            </div>
          </div>
          <div className='col-xs-12 col-md-4'>
            <div className="afb-input">
              <div className='mb-4'></div>
              <div className={`afb-checkbox ${autoConfirm ? 'checked' : ''}`} onClick={()=>setAutoConfirm(!autoConfirm)}>
                Auto Confirm Claim Requests
              </div>
            </div>
          </div>
        </div>
        <div className='pkgrewards'>
          <div className="afb-input">
            <label>Level 1 Reward (%)</label>
            <input className="cs-textinput" value={lvl1} onChange={(e)=>setLvl1(e.target.value)}></input>
          </div>
          <div className="afb-input">
            <label>Level 2 Reward (%)</label>
            <input className="cs-textinput" value={lvl2} onChange={(e)=>setLvl2(e.target.value)}></input>
          </div>
          <div className="afb-input">
            <label>Level 3 Reward (%)</label>
            <input className="cs-textinput" value={lvl3} onChange={(e)=>setLvl3(e.target.value)}></input>
          </div>
          <div className="afb-input">
            <label>Level 4 Reward (%)</label>
            <input className="cs-textinput" value={lvl4} onChange={(e)=>setLvl4(e.target.value)}></input>
          </div>
          <div className="afb-input">
            <label>Level 5 Reward (%)</label>
            <input className="cs-textinput" value={lvl5} onChange={(e)=>setLvl5(e.target.value)}></input>
          </div>
        </div>
        <div className="afb-footer">
          <button className="sbtn sbtn-primary" onClick={()=>saveChanges()}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default AdminSettings;