import React, {useState, useEffect, useCallback, useRef} from 'react'
import EditorBox from './EditorBox';
import UsersService from '../../services/users.service';

const titles = {
  'hb1': "Home Box 1",
  'hb2': "Home Box 2",
  'hb3': "Home Box 3",
  'faq': "FAQ",
  'tos': "Terms of Service",
  'plans': "Plans"
}

function AdminEditor({setCurrentJob, onFlash}) {
  const [content, setContent] = useState([]);
  
  const loadData = useCallback(async () => {
    try {
      setCurrentJob('Loading data...')
      const contentsRes = await UsersService.getContent()
      setContent(contentsRes.data)
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
    }
  },[])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <div className="admin-editor">
      {content.map((cn,k)=><EditorBox
        key={`eb${k}`}
        setCurrentJob={setCurrentJob}
        onFlash={onFlash}
        pos={cn.position}
        content={cn.content}
        title={titles[cn.position]}
      />)}
    </div>
  )
}

export default AdminEditor;