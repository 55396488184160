import React, {useState, useEffect, useCallback} from 'react'
import moment from 'moment'
import AdminService from '../../services/admin.service';

function AdminSubmissions(props) {
  const { setCurrentJob} = props
  const [submissions, setSubmissions] = useState([]);
  const [searchText, setSearchText] = useState('');

  const loadData = useCallback(async () => {
    setCurrentJob('Loading data...')
    const loadedsubmissions = await AdminService.getContactFormSubmissions()
    setSubmissions(loadedsubmissions.data)
    setCurrentJob('')
  },[])

  useEffect(() => {
    loadData()
  }, [loadData])

  const search = async () => {
    setCurrentJob('Loading data...')
    const loadedsubmissions = await AdminService.searchContactFormSubmissions(searchText)
    setSubmissions(loadedsubmissions.data || [])
    setCurrentJob('')
  }

  return (
    <div className="admin-settings">
      <div className="white-box mb-2">
        <h4 style={{textAlign: 'center'}}>Contact Form Submissions (last 70)</h4>
        <hr />
        <div className='search-bar'>
          <input className="cs-textinput mr-1" value={searchText} onChange={(e)=>{setSearchText(e.target.value)}} placeholder={"Search..."} />
          <button className='sbtn' onClick={()=>{search()}}>Search</button>
        </div>
        <hr />
        <table className='contact-table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Email</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Wallet</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((sb,indx)=><tr key={sb+indx}>
              <td>{moment(sb.createdAt).format('MMM DD')}</td>
              <td>{sb.email}</td>
              <td>{sb.subject}</td>
              <td>{sb.message}</td>
              <td>{sb.wallet}</td>
            </tr>)}
          </tbody>
        </table>
        
      </div>
    </div>
  );
}

export default AdminSubmissions;