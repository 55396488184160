import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const loc = useLocation()
  const search = loc.search
  return (
    <div className="footer">
      <div className="footer-links">
        <Link to={{ pathname: '/tos', search}} >Terms</Link>
        <Link to={{ pathname: '/faq', search}} >FAQ</Link>
      </div>
      <div>DexFee - Copyright © 2024. All rights reserved.</div>
    </div>
  );
}

export default Footer;