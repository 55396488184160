import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function Charts() {
  
  const [chartOptions1, setChartOptions1] = useState(null)
  const [chartOptions2, setChartOptions2] = useState(null)
  const [chartOptions3, setChartOptions3] = useState(null)

  async function getChartData(chartId) {
    const ccc = 9
    try {
      const chartDataRaw = await fetch(`https://www.binance.com/bapi/composite/v1/public/promo/cmc/cryptocurrency/detail/chart?id=${chartId}&range=1D`)
      const chartDataJson = await chartDataRaw.json()
      const data = chartDataJson.data.body.data.points
      let values = []
      const keys = Object.keys(data)
      keys.forEach((key) => {
        values.push(data[key]['v'][0])
      })
      return {
        chart: {
          title: '',
          type: 'areaspline',
          backgroundColor: 'rgba(0,0,0,0)',
          height: '150px',
          margin: [15, 0, 15, 60]
        },
        title:{
          text:''
        },
        yAxis: {
          min: Math.min(...values),
          visible: true,
          title: '',
          gridLineColor: 'rgba(255, 255, 255, 0.1)',
          labels:{
             formatter:function(){
                 return '$' + (this.value > 1000 ? this.value/1000 + 'K' : this.value);
             },
             style: {
              color: '#fff'
            }
          }
        },
        xAxis:{
          visible: false
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false,
          followPointer: false,
          followTouchMove: false
        },
        series: [{
          data: values,
          enableMouseTracking: false,
          lineColor: Highcharts.getOptions().colors[ccc],
          lineWidth: 1,
          marker: {
            enabled: false
          },
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, Highcharts.Color(Highcharts.getOptions().colors[ccc]).setOpacity(0.5).get('rgba')],
              [1, Highcharts.Color(Highcharts.getOptions().colors[ccc]).setOpacity(0).get('rgba')]
            ]
          }
        }],
      }
    } catch (err) {
      console.log('Failed to fetch assets data')
    }
  }
  
  async function setData() {
    const [_ch1, _ch2, _ch3] = await Promise.all([
      getChartData(1),
      getChartData(1027),
      getChartData(1839)
    ]);
    setChartOptions1(_ch1 || null)
    setChartOptions2(_ch2 || null)
    setChartOptions3(_ch3 || null)
  }
  useEffect(() => {
    setData()
  }, [])

  return (
    <div className="charts-wrapper white-box">
      <h3>Price Changes (Last 24 Hours)</h3>
      <div className="row tight">
        <div className="col-12 col-md-4">
          <div className="chart-wrapper">
            {chartOptions1 ? <HighchartsReact highcharts={Highcharts} options={chartOptions1} /> : null}
            <div className="chart-label">‌Bitcoin</div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="chart-wrapper">
            {chartOptions2 ? <HighchartsReact highcharts={Highcharts} options={chartOptions2} /> : null}
            <div className="chart-label">Ethereum</div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="chart-wrapper">
            {chartOptions3 ? <HighchartsReact highcharts={Highcharts} options={chartOptions3} /> : null}
            <div className="chart-label">Binance Coin</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Charts;