const tourSteps = {
    "/": [
        {
          element: ".show-add-token",
          intro: "View and import DexFee's exclusive tokens to your wallet."
        },
        {
            element: "#tour_packages",
            intro: "<b>Packages</b></br> Select an investment package and earn DEXO."
        },
        {
            element: "#tour_staking",
            intro: "<b>Staking</b></br> Put your DEXOs to work and earn passive income."
        },
        {
            element: "#tour_dashboard",
            intro: "<b>Dashboard</b></br> View your account information, assets and claim investment rewards."
        }
    ],
    "/dashboard": [
      {
        element: "#tour_assets",
        intro: "Some of the relevant tokens are displayed in this section."
      },
      {
        element: "#tour_claim_rewards",
        intro: "Based on the packages you have activated, you will rewarded by DEXO. You can claim these rewards and have it in your wallet after reaching a certain amount."
      },
      {
        element: "#tour_referrals",
        intro: "Invite your friends to join DexFee and receive commission for each package they activate."
      }

    ],
    "/packages": [
      {
        element: ".token-options",
        intro: "Choose your desired token to activate packages with."
      },
      {
        element: ".pkg",
        intro: "Select a package you are interested in. If it's the first time you want to activate a package, you should grant a spend allowance to DexFee."
      },
      {
        element: "#tour_packages_history",
        intro: "History of your investments will appear here."
      }
    ],
    "/staking": [
      {
        element: "#tour_stk_stats1",
        intro: "Current DEXO market price. This value may change over time."
      },
      {
        element: "#tour_stk_stats2",
        intro: "Annual Percentage Yield is the rate you can earn on an account over a year and it includes compound interest."
      },
      {
        element: "#tour_stk_stats3",
        intro: "The amount of DEXO in your wallet and available for staking."
      },
      {
        element: ".dexo-box",
        intro: "Amount of DEXOs you want to invest."
      },
      {
        element: ".stp_calculator",
        intro: "In this section you can review how much you can earn before really committing."
      },
      {
        element: ".stp_return_amount",
        intro: "Final amount of DEXOs that you could potentially earn after the given days."
      }
    ]
}
export default tourSteps