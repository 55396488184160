import React from "react";
import { parseEther } from "ethers/lib/utils";

function PackageButton ({stakePending, onPackageSelect, fundProblem, allowanceProblem, platformFeeProblem}) {
  let result = null
  if (platformFeeProblem) {
    result = <button className="sbtn sbtn-disabled">Platform Fee Required</button>
  } else if (stakePending) {
    result = <button className="sbtn sbtn-disabled">Processing...</button>
  } else if (fundProblem) {
    result = <button className="sbtn sbtn-disabled">Insufficient Funds</button>
  } else if (allowanceProblem) {
    result = <button className="sbtn sbtn-disabled">Activate</button>
  } else {
    result = <button className="sbtn sbtn-primary" onClick={() => onPackageSelect()}>Activate</button>
  }
  return result
}

function Package(props) {
  const {id, title, usdtPrice, onesdPrice, dexoPrice, onPackageSelect, stakePending, allowance, onApproveToken, description1, description2, activeCurrency, userTokens, platformFeeProblem} = props
  const usdtAllowanceShort = usdtPrice - allowance.usdt;
  const onesdAllowanceShort = onesdPrice - allowance.onesd;
  const dexoAllowanceShort = dexoPrice - allowance.dexo;

  let allowanceProblem = usdtAllowanceShort > 0
  if (activeCurrency === 'onesd' && onesdAllowanceShort > 0) allowanceProblem = true
  if (activeCurrency === 'dexo' && dexoAllowanceShort > 0) allowanceProblem = true

  let fundProblem = userTokens.usdt < usdtPrice
  if (activeCurrency === 'onesd' && userTokens.onesd < onesdPrice) fundProblem = true
  if (activeCurrency === 'dexo' && userTokens.dexo < dexoPrice) fundProblem = true

  return (
    <div className="pkg">
      <div className="pkg-header">
        <div className="pkg-title">{title}</div>
        <div className="pkg-price">
          <div>{usdtPrice.toLocaleString()} USDT</div>
          <div>+</div>
          {onesdPrice
            ? onesdPrice.toLocaleString() + " 1SD"
            : dexoPrice.toLocaleString() + " DEXO"}
        </div>
        <div className="pkg-note">{description1}</div>
        <div className="pkg-note">{description2}</div>
      </div>
      {allowanceProblem && (
        <div className="pkg-text" onClick={() => onApproveToken(usdtPrice, onesdPrice, dexoPrice, usdtAllowanceShort, onesdAllowanceShort, dexoAllowanceShort)}>
          Activating this package requires spend allowance.
          <span className="s-link">(click here to allow)</span>
        </div>
      )}
      <div className="pkg-button">
        <PackageButton
          fundProblem={fundProblem}
          allowanceProblem={allowanceProblem}
          platformFeeProblem={platformFeeProblem}
          stakePending={stakePending}
          onPackageSelect={()=>onPackageSelect(id.toString(), parseEther(usdtPrice.toString()).toString(), parseEther(onesdPrice.toString()).toString(), parseEther(dexoPrice.toString()).toString())}
        />
      </div>
    </div>
  );
}

export default Package;