import React, {useEffect} from "react";
import MarketTrend from "../components/MarketTrend";
import Charts from "../components/Charts";
import RSS from "../components/rss";
import "./home.scss";
import Boards from "../components/Boards";

function Home(props) {
  //const {moralisObj, setCurrentJob} = props
  //const {user, Moralis} = moralisObj

  useEffect(() => {
    document.body.classList.add('force-scroll');
    return () => {
      document.body.classList.remove('force-scroll');
    };
  }, []);
  return <div className="container">
    <div className="big-intro">
      <h1>Make Money Through <span>DexFee</span> Realtime</h1>
      <p>DexFee is a secure and safe investment platform. DexFee offers various investment plans and can help you grow your savings faster than traditional savings accounts.</p>
    </div>
    <Boards {...props} />
    <Charts />
    <div className="row tight mb-3">
      <div className="col-12 col-md-8 mb-3">
        <div className="white-box ">
          <MarketTrend />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <RSS />
      </div>
    </div>
  </div>
}

export default Home;