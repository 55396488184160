import React, {useEffect, useState} from "react";
import Web3 from "web3";
import adminService from "../services/admin.service";
import {snimda, networkId, blockExplorerUrl, chainTitle, rpcUrl, nativeCurrency} from "../constants"


function Header({moralisObj, auth, elevated, setElevated, onDisplayModal, refStatus}) {
  const {isAuthenticated, Moralis, user} = moralisObj

  const [wrongNet, setWrongNet] = useState(false)

  useEffect(()=>{
    const cookies = document.cookie.split('; ')
    const signature = cookies.filter(ck=>ck.split('=')[0] === 'dfsg')
    const userKey = cookies.filter(ck=>ck.split('=')[0] === 'dfk')
    const web3 = new Web3(Moralis.provider)
    if (signature.length>0 && userKey.length>0) {
      const sig = signature[0].split('=')[1]
      const uk = userKey[0].split('=')[1]
      const res = web3.eth.accounts.recover(uk, sig)
      if (user) {
        setElevated(user.attributes.accounts[0] === res.toLowerCase())
      } else {
        setElevated(false)
      }
    }
    if (window.ethereum) window.ethereum.on('networkChanged', function(){checkNetwork()});
  },[])

  useEffect(()=>{
    checkNetwork()
  },[isAuthenticated])

  async function elevate() {
    const userAddress = user.attributes.accounts[0]
    let keyToSign = await adminService.refreshKey({wallet: userAddress})
    keyToSign = keyToSign.data.key
    const web3 = new Web3(Moralis.provider)
    try {
      const signedData = await web3.eth.personal.sign(keyToSign, userAddress)
      document.cookie = `dfsg=${signedData};max-age=${30*24*3600};`
      document.cookie = `dfk=${keyToSign};max-age=${30*24*3600};`
      const res = web3.eth.accounts.recover(keyToSign, signedData)
      setElevated(userAddress === res.toLowerCase())
    } catch (err) {
      setElevated(false)
      document.cookie = `dfsg='';max-age=0;`
      document.cookie = `dfk='';max-age=0;`
    }
  }

  async function checkNetwork() {
    if (Moralis.provider) {
      const web3 = new Web3(Moralis.provider)
      const detectedNet = await web3.eth.net.getId()
      if (detectedNet != networkId) {
        setWrongNet(true)
      } else {
        setWrongNet(false)
      }
    }
  }

  async function addNetwork() {
    const web3 = new Web3(Moralis.provider)
    if (window.ethereum) {
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{ 
            chainId: web3.utils.toHex(networkId),
            chainName: chainTitle,
            nativeCurrency: {
                name: nativeCurrency,
                symbol: nativeCurrency,
                decimals: 18
            },
            rpcUrls: [rpcUrl],
            blockExplorerUrls: [blockExplorerUrl]
        }],
      })
      .then(() => console.log('network added'))
      .catch(() => console.log('could not add network'))
    }
  }

  return (
  <>
    {refStatus === 1 ?
      <div className={(isAuthenticated && user && wrongNet) ? 'header has-alert' : 'header'} key='dfHeader'>
      {isAuthenticated && user ? <button className="sbtn sbtn-primary show-add-token" onClick={onDisplayModal}>Add Tokens</button> : null }
      {(!isAuthenticated && !user) ? <div className="sgn-wrapper">
        <button className="sbtn sbtn-primary mm-sgn" onClick={() => auth(false)}>Sign in (Metamask)</button>
        <button className="sbtn sbtn-primary wc-sgn ml-2" onClick={() => auth(true)}>Sign in (Wallet Connect)</button>
      </div> : 
      <div className={`user-address ${elevated ? 'elevated' : ''}`} onClick={()=>snimda.includes(user.attributes.ethAddress.toLowerCase()) ? elevate() : null}>
          <i className="fa-light fa-wallet"></i> <span className="full-address">{user.attributes.ethAddress}</span> <span className="short-address">{`${user.attributes.ethAddress.slice(0,6)}......${user.attributes.ethAddress.slice(-6)}`}</span>
      </div>}
      {isAuthenticated && user && wrongNet ? <pre className="wrong-net">
        <span onClick={()=>addNetwork()}>DexFee is only available on {chainTitle}. You are currently using another network. Click here to switch.</span>
      </pre> : null}
    </div> :
      <div className='header guest-mode' key='dfHeader'>
        <div>You need a valid referral link to enter the program.</div>
      </div>
    }
  </>
  );
}

export default Header;