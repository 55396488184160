import React, {useState, useEffect} from 'react'
import UsersService from '../services/users.service';

function TOS({setCurrentJob}) {
  const [tos, setTos] = useState('');

  useEffect(() => {
    loadData()
  }, [])

  async function loadData () {
    try {
      setCurrentJob('Loading data...')
      const contentsRes = await UsersService.getContent()
      for (let cn of contentsRes.data) {
        if(cn.position === 'tos') setTos(cn.content)
      }
    } catch (err) {
    } finally {
      setCurrentJob('')
    }
  }

  return <div className="container">
    <div className="white-box">
      <h3>Terms of Service</h3>
      <div dangerouslySetInnerHTML={{ __html: tos }}></div>
    </div>
  </div>
}

export default TOS;