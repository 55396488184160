import axios from "axios"
import { BASE_URL } from "../constants";
import authHeader from "./authHeader"

class PackagesService {
  //stake(hash, wallet) {
  //  return axios.post(BASE_URL + '/stake', {hash, wallet}, { headers: authHeader() });
  //}
  getAllPackages() {
    return axios.get(BASE_URL + '/packages/get-packages');
  }
  savePackages(data) {
    return axios.post(BASE_URL + '/packages/save-packages', data, { headers: authHeader() });
  }
}

export default new PackagesService();