import React, {useState, useEffect, useCallback} from 'react'
import { contractAbi, contractAddress } from '../../constants';
import PackagesService from '../../services/packages.service';

function AdminPackages(props) {
  const {moralisObj, setCurrentJob, onFlash} = props
  const {Moralis, user} = moralisObj
  const [packagesData, setPackagesData] = useState([]);
  const [oldPrices, setOldPrices] = useState([]);
 
  const loadData = useCallback(async () => {
    try {
      setCurrentJob('Loading data...')
      const packages = await PackagesService.getAllPackages()
      setOldPrices(packages.data.map(pkg=>pkg.price + '000000000000000000'))
      setPackagesData(packages.data)
    } catch (err) {
      console.log(err)
      onFlash({message: 'There was a problem while loading data. check the console.', type:'danger'})
    } finally {
      setCurrentJob('')
    }
  },[Moralis.Query])

  function onChange(id, name, value) {
    const updatedData = packagesData.map(pkg=>{
      if (pkg._id === id) pkg[name] = value
      return pkg
    })
    setPackagesData(updatedData)
  }

  useEffect(() => {
    loadData()
  }, [loadData])

  async function savePackageChanges() {
    setCurrentJob('Waiting for authentication...')
    const pricesArray = packagesData.map(pkg=>pkg.price + '000000000000000000')

    const fn = {
      contractAddress,
      functionName: "setDefaultPackages",
      abi: contractAbi,
      params: {newDefaultPackages: pricesArray}
    }
    try {
      if (JSON.stringify(oldPrices) === JSON.stringify(pricesArray)) {
        const res3 = await PackagesService.savePackages({packages: packagesData, wallet: user.attributes.accounts[0]})
        if (res3.data.ok === 1) onFlash({message: 'Data has been saved', type:'success'})
      } else {
        const res1 = await Moralis.executeFunction(fn)
        setCurrentJob("Updating contract...")
        const res2 = await res1.wait()
        setCurrentJob("Finalizing...")
        if (res2.status === 1) {
          const res3 = await PackagesService.savePackages({packages: packagesData, wallet: user.attributes.accounts[0]})
          if (res3.data.ok === 1) onFlash({message: 'Data has been saved', type:'success'}) 
        }
      }
    } catch (err) {
      onFlash({message: 'There was a problem while saving data. check the console.', type:'danger'})
      console.log(err)
    } finally {
      setCurrentJob('')
    }
  }
  return (
    <div className="admin-settings">
      <div className='row tight'>
        {packagesData.map(pkg=><div className='col-12 col-md-6' key={pkg._id}>
          <div className="admin-fn-box white-box">
            <h4>{pkg.title}</h4>
            <div className="afb-body">
              <div className="afb-input">
                <div className={`afb-checkbox ${pkg.active ? 'checked' : ''}`} onClick={()=>onChange(pkg._id, 'active', !pkg.active )}>
                  Show
                </div>
              </div>
              <div className="afb-input">
                <label>Title</label>
                <input value={pkg.title} className="cs-textinput" onChange={e=>onChange(pkg._id, 'title', e.target.value)}/>
              </div>
              <div className="afb-input">
                <label>Price</label>
                <input value={pkg.price} className="cs-textinput" onChange={e=>onChange(pkg._id, 'price', Number(e.target.value))}/>
              </div>
              <div className="afb-input">
                <label>Description 1</label>
                <input value={pkg.description1} className="cs-textinput" onChange={e=>onChange(pkg._id, 'description1', e.target.value)}/>
              </div>
              <div className="afb-input">
                <label>Description 2</label>
                <input value={pkg.description2} className="cs-textinput" onChange={e=>onChange(pkg._id, 'description2', e.target.value)}/>
              </div>
            </div>
          </div>
        </div>)}
      </div>
      <div className="white-box wide-save">
          <button className="sbtn sbtn-primary" onClick={()=>savePackageChanges()}>Save</button>
      </div>
    </div>
  );
}

export default AdminPackages;